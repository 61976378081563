import React from 'react'
import Layout from '../components/layout'
import Header from '../components/header'
import AboutContent from '../components/AboutContent'
import { SplitLayoutContainer } from '../components/styled'
import ProjectMenu from '../components/ProjectMenu'

const IndexPage = () => {
  return (
    <Layout>
      <Header />
      <SplitLayoutContainer>
        <AboutContent />
        <ProjectMenu />
      </SplitLayoutContainer>
    </Layout>
  )
}

export default IndexPage
