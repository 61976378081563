import React from 'react'
import { Link } from 'gatsby'
import styled, { withTheme } from 'styled-components'

const LinkStyled = styled(Link)(p => ({
  color: p.theme.colours.secondary,
  display: 'block',
  textDecoration: 'none',
  lineHeight: '1.2em',
  textAlign: 'center',
  opacity: 0.7,

  ...p.theme.h2,
  '&.active': {
    opacity: 1,
    // color: 'red',
    mixBlendMode: 'difference',
  },
}))

const ProjectLink = ({ children, to, theme, ...rest }) => {
  return (
    <LinkStyled to={to} theme={theme} {...rest}>
      {children}
    </LinkStyled>
  )
}

export default withTheme(ProjectLink)
