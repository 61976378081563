import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import { IntroP, SplitLayoutItem } from './styled'
import { devices } from '../theme'
import styled from 'styled-components'

const CenterContent = styled(SplitLayoutItem)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: left;
  padding: 32px;
`
const CopyContainer = styled.div`
  max-width: 630px;
`

const AboutContent = ({ showFullContent }) => {
  return (
    <CenterContent>
      <CopyContainer>
        <h1>
          Hello I’m Paddy Hamilton, <br />a Front-End Developer & UI Designer
          based in Glasgow.
        </h1>
        <p>
          Crafting products for clients in the Creative, Financial, Charity and
          Science sector. My favourit tools are React, Node, Graphql &
          Figma.
        </p>
        <p>
          Currently @ <a href="https://www.deepmatter.io/">deepmatter.io</a>
        </p>
      </CopyContainer>
    </CenterContent>
  )
}
AboutContent.defaulProps = {
  showFullContent: false,
}

export default AboutContent
