import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { SplitLayoutItem } from './styled'
import ProjectLink from './ProjectLink'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'

const Container = styled(SplitLayoutItem)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`
const BgImage = styled(Img)`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute !important;
  height: 100%;
  filter: grayscale(100%) contrast(5);
  mix-blend-mode: screen;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0 !important;
  &::before,
  &::after {
    opacity: 0;
  }
  &.active {
    opacity: 1 !important;
    &::before,
    &::after {
      opacity: 1 !important;
    }
  }
`
const MenuItemsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
function ProjectMenu() {
  const {
    allContentfulProject: { edges: projects },
  } = useStaticQuery(graphql`
    query {
      allContentfulProject {
        edges {
          node {
            slug
            id
            name
            colours
            heroImage {
              fluid(maxWidth: 1280) {
                ...GatsbyContentfulFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  `)

  const [activeNumber, setActiveNumber] = useState(0)
  const handleSetActiveOnMouseEnter = index => {
    if (activeNumber === index) return

    setActiveNumber(index)
  }
  const updateActiveOnTimeout = () =>
    setTimeout(() => {
      setActiveNumber(
        activeNumber =>
          activeNumber === projects.length ? 0 : activeNumber + 1
      )
      //*restart
      updateActiveOnTimeout()
    }, 2000)
  const bgImagesData = projects.map(p => p.node.heroImage.fluid)
  // updateActiveOnTimeout()

  return (
    <Container
      style={{ backgroundColor: projects[activeNumber].node.colours[0] }}
    >
      {bgImagesData &&
        bgImagesData.map((bgImageData, i) => {
          console.log('activeNumber === i', activeNumber === i)
          return (
            <BgImage
              fluid={bgImageData}
              key={projects[i].node.slug}
              className={activeNumber === i ? 'active' : ''}
            />
          )
        })}

      <MenuItemsContainer>
        {projects &&
          projects.map(({ node: { id, slug, name, colours } }, i) => (
            <ProjectLink
              key={id}
              to={slug}
              style={{ color: activeNumber === i ? colours[0] : 'inherit' }}
              className={activeNumber === i ? 'active' : ''}
              onMouseEnter={() => handleSetActiveOnMouseEnter(i)}
            >
              {name}
            </ProjectLink>
          ))}
      </MenuItemsContainer>
    </Container>
  )
}

export default ProjectMenu
